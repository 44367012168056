import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateCompiler, TranslateModule } from "@ngx-translate/core";
import { StoredArtistProfile } from "src/app/models/artist/stored-artist-profile";
import { ArtistProfileActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { ArtistProfileSelectors } from "src/app/ngrx/selectors";

@Component({
  selector: "yr-artist-profile-link",
  templateUrl: "./artist-profile-link.component.html",
  styleUrls: ["./artist-profile-link.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink],
})
export class ArtistProfileLinkComponent implements OnInit {
  isLoading: boolean = true;
  artistProfile?: StoredArtistProfile;
  artistProfileLink?: string;

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(
      ArtistProfileActions.loadArtistProfileForCurrentUser({}),
    );

    this.store
      .select(ArtistProfileSelectors.selectArtistProfileForCurrentUser)
      .subscribe((data) => {
        if (data.profile?.customUrl) {
          this.artistProfileLink = `/@${data.profile.customUrl}`;
        } else {
          this.artistProfileLink = data.profile
            ? `/artists/${data.profile.name}`
            : undefined;
        }

        this.artistProfile = data.profile;
        this.isLoading = data.isLoading;
      });
  }
}
