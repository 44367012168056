import { Component } from "@angular/core";

@Component({
  selector: "yr-tool-bar-second-row",
  templateUrl: "./tool-bar-second-row.component.html",
  styleUrls: ["./tool-bar-second-row.component.scss"],
  standalone: true,
  imports: [],
})
export class ToolBarSecondRowComponent {}
